globalThis["__sentryRewritesTunnelPath__"] = "/tunnel";
globalThis["SENTRY_RELEASE"] = {
  "id": "6c1ecfc7efc4562abd4568e629bdb0a48202882a"
};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { getEnv, getRelease, isProduction } from '@motimate/utils';
import * as Sentry from '@sentry/nextjs';
import UserAgent from '@/lib/user-agent';
Sentry.init({
  enabled: getEnv() !== 'development',
  release: getRelease(),
  environment: getEnv(),
  dsn: 'https://d1c761fdc1324d628665534d4379f6aa@o142255.ingest.us.sentry.io/1181375',
  tracesSampleRate: isProduction() ? 0.001 : 0,
  maxValueLength: 2048,
  normalizeDepth: 10,
  sampleRate: 1,
  ignoreErrors: [/Cannot read property 'regions' of undefined/, /document\.getElementsByClassName\("docs-homescreen-gb-container"\)\[0\]\.style/, /ResizeObserver loop limit exceeded/,
  // Email link Microsoft Outlook crawler compatibility error
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  /Non-Error promise rejection captured with value\: Object Not Found Matching Id\:/,
  // Bing Instant Search error in Edge on iOS
  // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
  /Can't find variable\: instantSearchSDKJSBridgeClearHighlight/, /window\.instantSearchSDKJSBridge\.onTextSelected is not a function/],
  beforeSend: function beforeSend(event) {
    if (UserAgent.isErrorSampling()) {
      return event;
    }
    return null;
  }
});